export const validationVoting = {
  order: 3,
  name: "Vote on validations",
  keywords: "accept reject skip stake staking",
  subcategory: "Validate database changes",
  markdown: `# Vote on validations

  Validating a datapoint means voting on it. There are three choices:

  - {% inlineAppIcon iconName="check" /%}**Accept**: You agree with the change.
  - {% inlineAppIcon iconName="xMark" /%}**Reject**: You disagree with the change.
  - {% inlineAppIcon iconName="skip" /%}**Skip**: You are unsure whether the change is correct.

  ## What to expect

  There are two important aspects of voting that you should be aware of:

  - To submit a vote you must {% inlineRouterLink articleId="what-is-staking?" %}stake a small amount of money{% /inlineRouterLink %} that is returned (with a reward!) upon successful outcomes.
  - Changes are finalized asynchronously, so you will not know the outcome right away. Learn more about {% inlineRouterLink articleId="understand-validation" sectionName="Validation outcomes" %}validation outcomes{% /inlineRouterLink %}.

  ## Additions and removals

  Remember, a change may be trying to add **or** remove information. Make sure your vote matches your desired outcome:

  ### Addition

  - {% inlineAppIcon iconName="check" /%}**accepting** an **addition** means you think the information **belongs** in the database.
  - {% inlineAppIcon iconName="xMark" /%}**rejecting** an **addition** means you think the information **does not belong** in the database.

  ### Removal

  - {% inlineAppIcon iconName="check" /%}**accepting** a **removal** means you think the information **does not belong** in the database.
  - {% inlineAppIcon iconName="xMark" /%}**rejecting** a **removal** means you think the information **belongs** in the database.

  ## Skips

  Commercial real estate is both vast and deep, so there are many reasons why you might not know whether a proposed change belongs in the database. When you feel this way, use the {% inlineAppIcon iconName="skip" /%}**Skip button**! Combined with {% inlineRouterLink articleId="what-is-staking?" %}staking{% /inlineRouterLink %}, skipping encourages validators to act in good faith. However, skips are not infinite:

  ### Skips are limited

  New users are granted 10 skips. Each use of the {% inlineAppIcon iconName="skip" /%}**Skip button** spends a skip. Each use of the {% inlineAppIcon iconName="check" /%}**Accept button** or {% inlineAppIcon iconName="xMark" /%}**Reject button** grants a skip.

  {% callout type="tip" %}
  **Tip:** If you run out of skips and feel stuck, take a break from validation. Try to learn more about your market by meeting people, reading the news, and even researching on Tower Hunt! As you become more experienced, you'll start to encounter more validations where you can vote with conviction.
  {% /callout %}

  ## Next steps

  Now that you understand how to vote on validations, make sure you know {% inlineRouterLink articleId="understand-validation" sectionName="Validation outcomes" %}the outcomes and rewards of validation{% /inlineRouterLink %} &mdash; this is the fun part!`,
};
